<template>
  <div class="mt-4">
    <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full">
      <div class="flex justify-center items-center">
        <h1 class="heading-2 text-center">Record Payment</h1>
        <div class="flex items-center gap-3">
          </div>
      </div>
    </div>
    <div class="flex justify-center" >
      <div class=" card rounded-xl bg-white p-4 my-4 sm:w-full lg:w-10/12 xl:w-8/12 2xl:w-6/12">
        <div>
            <div class="">
                <div class="flex justify-between gap-4">
                  <div class="mb-4" v-if="custDetails !== null">
                      <p class="text-gray4">Customer</p>
                      <p class="text-text1 heading-3 font-bold">{{custDetails.companyName}}</p>
                  </div>
                  <div class="mb-4 w-6/12 ">
                          <TextField :placholderText="`Payment Date`" :setReadOnly="true" :autoFocus="false" :inputext="savePaymentObj.addedDate" :showDate="true" :lableText="'Payment Date'" :inputId="'payaddDateid'" @onClickInputAction="payAddDatePickShow = true" />
                          <div v-if="payAddDatePickShow" class="popup_overlay">
                              <div style="height: 435px; width: 306px;" class="custom_dialog rounded-xl border border-gray4 ring-gray4 ">
                                  <DateTimePickerCustom
                                      v-model="savePaymentObj.addedDate"
                                      v-if="payAddDatePickShow"
                                      :showDate="true"
                                      :title="'Payment Date'"
                                      :showOnlyDate="true"
                                      :showStartDate="true"
                                      :hideClear="true"
                                      :projectSection="true"
                                      @closeDateTimepickerDialog="payAddDatePickShow = false">
                                  </DateTimePickerCustom>
                              </div>
                          </div>
                  </div>
                </div>
                <div v-if="invoiceList.length > 0">
                <p v-if="invoiceList.length > 1" class="text-primary text-center py-4">Pleas select invoices for this payment</p>
                  <div v-for="(invoice, i) in invoiceList" :key="i" class="mb-4">
                  <div class="grid grid-cols-2 gap-4">
                    <div class="flex justify-between">
                      <div>
                        <CheckBoxInput
                            :checkValue="invoice.selected"
                            :label="``"
                            :disabled="false"
                            @setIsActive="invoice.selected = !invoice.selected; calculateAmount(invoice, i)"  />
                      </div>
                      <div>
                        <p  class="text-text1 font-bold text-right">Invoice # {{invoice.invoiceNumber}}</p>
                        <p  class="text-gray4 heading-6 text-right">Total:  {{invoice.grandTotal.toFixed(2) | amountOnlyNumber}}</p>
                        <p  class="text-gray4 heading-6 text-right">Due:  {{(invoice.grandTotal-invoice.recordedPayment).toFixed(2)| amountOnlyNumber}}</p>
                      </div>
                    </div>
                    <div>
                      <div class="mb-2 mt-2">
                        <TextField
                        :inputId="`itmprice${i}`"
                        :inputext="invoice.selected ? invoice.recordAmount : 0"
                        :placholderText="``"
                        :lableText="''"
                        :inputType="'number'"
                        :textRight="true"
                        :autoFocus="false"
                        :setReadOnly="false"
                        :inputTypeText="'number'"
                        @focus="selectededInvIndex = i"
                        @blurAction="amountSetBlur"
                        @inputChangeAction="setItemPrice"  />
                      </div>
                    </div>
                  </div>
                  <div class="divider my-2"></div>
                </div>
                <div>
                  <div class="text-text1 text-center flex justify-between pb-4 ">
                    <div class="pl-0 sm:pl-44"> 
                      <p class="font-bold">Total Payment Recorded</p>
                      <p v-if="invoiceSelectErr !== ''" class="text-error heading-7 ">{{invoiceSelectErr}}</p>
                    </div>
                  <span class="font-bold">{{savePaymentObj.paymentAmount.toFixed(2) | amountOnlyNumber}}</span>
                  </div>
                </div>
                  
                <div class="sm:grid grid-cols-2 gap-4">
                  <div class="mb-4 sm:mb-0">
                    <Dropdown 
                        :inputext="searchForPayMehtod"
                        :inputId="'paymenthodsearch'"
                        :lableText="'Payment Method'"
                        :placholderText="`Payment Method`"
                        :autoFocus="false"
                        :showArrow="true"
                        :setReadOnly="false"
                        :searchCallApi="false"
                        :fieldError="paymentMethodErr !== ''"
                        :showPlus="true"
                        :keyName="'paymentMethodName'"
                        :listId="'paymaentmethodlist'"
                        :items="payMetondLi"
                        @keyPressAction="paymentMethodErr = ''"
                        @addNew="addNewPayMethod"
                        @selectNewForVal="selectNewPaymethod"
                        />
                        
                        <p v-if="paymentMethodErr !== ''" class="text-error heading-7">{{paymentMethodErr}}</p>
                  </div>
                  <div class="mb-4 sm:mb-0">
                        <TextField
                        :inputId="'transactionaid'"
                        :textMaxlength="100"
                        :showcharLimit="true"
                        :inputext="savePaymentObj.transactionId"
                        :placholderText="``"
                        :lableText="'Transaction Ref'"
                        :autoFocus="false"
                        @inputChangeAction="(data) => savePaymentObj.transactionId = data"  />
                    </div>
                  </div>
                  <div class="my-4 sm:mb-0">
                      <TextField
                        :inputId="'payemtnnote'"
                        :textMaxlength="100"
                        :showcharLimit="true"
                        :inputext="savePaymentObj.paymentNotes"
                        :placholderText="`Write note here`"
                        :lableText="'Payment Note'"
                        :autoFocus="false"
                        @inputChangeAction="(data) => savePaymentObj.paymentNotes = data"  />
                    </div>
                  </div>
            </div>
        </div>
        <div class="flex justify-end mt-4">
          <div class="text-rigth flex gap-2">
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="$router.go(-1)"/>
            <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="savePaymentRecord()"/>
          </div>
        </div>
        </div>
    </div>
    <AddNewPayMeth v-if="addNewPaymeth"/>
   </div>
</template>
<script>
import MyJobApp from "@/api/MyJobApp.js";
import Button from '@/View/components/globalButton.vue'
// import ItemDetail from './components/ItemDetail.vue'
import AddNewPayMeth from '@/View/setting/payMethod/components/AddNewPayMeth.vue'
import Dropdown from '@/View/components/dropdown.vue'
import DateTimePickerCustom from '@/View/components/dateTimepicker'
import TextField from '@/View/components/textfield.vue'
// import NumberInput from '@/View/components/number-text-field.vue'
import CheckBoxInput from '@/View/components/checkBoxInput.vue'
import moment from 'moment'
export default {
  name: "customer",
  components: {
    Button,
    CheckBoxInput,
    AddNewPayMeth,
    // NumberInput,
    TextField,
    DateTimePickerCustom,
    Dropdown,
  },
//   mixins: [deboucneMixin],
  data() {
    return {
        searchForPayMehtod: '',
        paymentMethodErr: '',
        invoiceSelectErr: '',
        selectededInvIndex: 0,
        custDetails: null,
        payAddDatePickShow: false,
        addNewPaymeth: false,
        payementDetail: null,
        payMetondLi: [],
        invoiceList: [],
        savePaymentObj: {
          paymentId: 0,
          customerId: 0,
          paymentAmount: 0,
          paymentNotes: '',
          paymentMethodId: 0,
          transactionId: '',
          addedDate: '',
          linkedInvoiceList: [],
        }
    };
  },
  created() {},
  computed: {
  },
  beforeMount () {
    this.savePaymentObj.addedDate = moment(new Date()).format('YYYY-MM-DD')
    this.savePaymentObj.customerId = parseInt(this.$route.query.customerId)
  },
  mounted() {
    this.getCustomerDetail()
    if (parseInt(this.$route.query.invoiceId) > 0) {
      this.getPaymentDetail()
    }
    this.getPayMethodsList()
    this.getPaymentList()
    this.$root.$on('addNePayMethodHandler', (response, data) => {
      if (data !== null) {
        this.searchForPayMehtod = data.paymentMethodName
        this.savePaymentObj.paymentMethodId = data.paymentMethodId
      } 
      this.addNewPaymeth = false
    })
  },
  methods: {
    addNewPayMethod () {
      this.addNewPaymeth = true
    },
    selectNewPaymethod (data) {
      this.paymentMethodErr = ''
      this.searchForPayMehtod = data !== null ? data.paymentMethodName : ''
      this.savePaymentObj.paymentMethodId = data !== null ? data.paymentMethodId : 0
    },
    savePaymentRecord () {
      let valid = true
      if (this.savePaymentObj.paymentAmount === 0) {
        valid = false
        this.invoiceSelectErr = 'Please select invoice for this payment'
      }
      if (this.savePaymentObj.paymentMethodId === 0) {
        valid = false
        this.paymentMethodErr = 'Please select payment method'
      }
      if (valid) {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        MyJobApp.savePaymentRecord(
          this.savePaymentObj,
          response => {
            this.$router.go(-1)
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
      }
      
    },
    calculateAmount (data, index) {
      if (data.selected) {
        this.invoiceList[index].recordAmount = data.grandTotal - data.recordedPayment
        this.savePaymentObj.linkedInvoiceList.push({
          mappingId: 0,
          invoiceId: data.invoiceId,
          recordedAmount: data.grandTotal-data.recordedPayment,
        })
        
      } else {
        this.invoiceList[index].recordAmount = 0
        let findex = this.savePaymentObj.linkedInvoiceList.findIndex(x => x.invoiceId === parseInt(data.invoiceId))
        if (findex >= 0) {
          this.savePaymentObj.linkedInvoiceList.splice(findex, 1)
        }
      }
      this.calculatePayAm()
    },
    calculatePayAm () {
      this.invoiceSelectErr = ''
      this.savePaymentObj.paymentAmount = 0
      this.invoiceList.map(data => {
        this.savePaymentObj.paymentAmount += Number(data.recordAmount)
      })
    },
    setItemPrice (value) {
      let duValue = this.invoiceList[this.selectededInvIndex].grandTotal - this.invoiceList[this.selectededInvIndex].recordedPayment
      if (Number(value) > 0) {
        this.invoiceList[this.selectededInvIndex].selected = true
        this.invoiceList[this.selectededInvIndex].recordAmount = duValue < Number(value) ? duValue : Number(value)
        
        let findex = this.savePaymentObj.linkedInvoiceList.findIndex(x => x.invoiceId === this.invoiceList[this.selectededInvIndex].invoiceId)
        if (findex >= 0) {
          this.savePaymentObj.linkedInvoiceList[findex].recordedAmount = Number(value)
        } else {
          this.savePaymentObj.linkedInvoiceList.push({
          mappingId: 0,
          invoiceId: this.invoiceList[this.selectededInvIndex].invoiceId,
          recordedAmount: this.invoiceList[this.selectededInvIndex].grandTotal-this.invoiceList[this.selectededInvIndex].recordedPayment,
        })
        }
      } else if (value === '') {
        this.invoiceList[this.selectededInvIndex].recordAmount = 0
      } else {
        this.invoiceList[this.selectededInvIndex].recordAmount = value
      }

      setTimeout(() => {
        if ( Number(this.invoiceList[this.selectededInvIndex].recordAmount) === 0) {
          this.invoiceList[this.selectededInvIndex].selected = false
          this.savePaymentObj.linkedInvoiceList.splice(this.selectededInvIndex, 1)
        }
        this.calculatePayAm()
      }, 100);
      
       
    },
    amountSetBlur () {
    },
    getCustomerDetail () {
        this.$store.dispatch("SetLoader", {status: true, message: "Loading...", });
        MyJobApp.getCustBasicDetail(
        parseInt(this.$route.query.customerId),
        (response) => {
          this.custDetails = response.Data;
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
        }
      );
    },
    getPaymentList () {
        this.$store.dispatch("SetLoader", {status: true, message: "Loading...", });
        MyJobApp.listWithPendingPaymen(
        '',
        'invoiceDate',
        'DESC',
        parseInt(this.$route.query.customerId),
        (response) => {
          let tempList = []
          let list = response.Data.tableRow
          if (list !== null) {
            let findex1 = list.findIndex(x => x.invoiceId === parseInt(this.$route.query.invoiceId))
            console.log("findex1", findex1);
            if (findex1 >= 0) {
              tempList.push({...list[findex1], selected: true, recordAmount: list[findex1].grandTotal-list[findex1].recordedPayment  })
              this.savePaymentObj.paymentAmount = list[findex1].grandTotal-list[findex1].recordedPayment
            
              this.savePaymentObj.linkedInvoiceList.push({
                mappingId: 0,
                invoiceId: list[findex1].invoiceId,
                recordedAmount: list[findex1].grandTotal-list[findex1].recordedPayment,
              })
            }
            
            list.map(data => {
                if (data.invoiceId !== parseInt(this.$route.query.invoiceId)) {
                  tempList.push({...data, selected: false, recordAmount: 0  })
                }
            })
            this.invoiceList = tempList 
            if (parseInt(this.$route.query.paymentId) > 0) {
              this.getPayDetailForEdit()
            }
          }
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    getPaymentDetail () {
        this.$store.dispatch("SetLoader", {status: true, message: "Loading...", });
        MyJobApp.getPaymentDetail(
        parseInt(this.$route.query.invoiceId),
        (response) => {
          // this.getPaymentList()
          this.payementDetail = response.Data 
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    getPayDetailForEdit () {
        this.$store.dispatch("SetLoader", {status: true, message: "Loading...", });
        // MyJobApp.paymentDetailForEdit(
        MyJobApp.getPyamentDetail(
        parseInt(this.$route.query.paymentId),
        (response) => {
          this.savePaymentObj.paymentId = response.Data.paymentId
          this.savePaymentObj.customerId = response.Data.customerId
          this.savePaymentObj.paymentAmount = response.Data.paymentAmount
          this.savePaymentObj.paymentNotes = response.Data.paymentNotes
          this.savePaymentObj.transactionId = response.Data.transactionId
          if (response.Data.paymentDate !== '') {
            this.savePaymentObj.addedDate = moment(new Date(response.Data.paymentDate)).format('YYYY-MM-DD')
          }
          this.savePaymentObj.paymentMethodId = response.Data.paymentMethodId
          this.searchForPayMehtod = response.Data.paymentMethodName

          let invList = response.Data.linkedInvoiceList
           this.savePaymentObj.linkedInvoiceList = []
          // this.invoiceList = invList
            
          invList.map((data) => {
            let findex1 = this.invoiceList.findIndex(x => x.invoiceId === data.invoiceId)
            if (findex1 >= 0) {
              this.invoiceList[findex1].recordAmount = data.recordedAmount
              this.invoiceList[findex1].selected = true
            
              this.savePaymentObj.linkedInvoiceList.push({
                mappingId: 0,
                invoiceId: this.invoiceList[findex1].invoiceId,
                recordedAmount: data.recordedAmount,
              })
            }
          })
          this.invoiceList.reverse();
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    getPayMethodsList () {
        this.$store.dispatch("SetLoader", {status: true, message: "Loading...", });
        MyJobApp.paymethodList(
        (response) => {
          this.payMetondLi = response.Data 
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    }
  },
  beforeDestroy() {
    // this.$root.$off("addCustomerHandler");
  },
};
</script>
<style scoped>

</style>